/* Filter Box */
.admin-panel-filter-box {
    display: inline-block;

    border: white 1px solid;
    border-radius: 4px;

    margin: 5px;
    padding: 5px;

    min-height: 80px;
}

.admin-panel-filter-box__header {
    margin: 3px auto 5px auto;
}

/* Filter Box - Item */
.admin-panel-filter-box-item__label {
    margin-right: 5px;
}
