.job-form__submit-button {
    margin: 20px 0px;
}

.job-form__textarea-input {
    height: 50px;
    margin: auto 0;
}

.job-form__job-title {
    width: 250px;
}

.job-form__company-name {
    width: 200px;
}

.job-form__job-link {
    width: 320px
}

.job-form__existing-jobs {
    background-color: black;
    border-radius: 4px;
    padding: 8px;
    display: inline-block;
    width: 90%;
    max-width: 600px;
}

.job-form__existing-jobs li {
    list-style-type: none;
}