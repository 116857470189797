.job-list-item {}

.job-list-item__title-company-box {
    margin: 8px 0px;
}
.job-list-item__job-title {
    margin: 5px;
}
.job-list-item__company {
    margin: 0px;
}

.job-list-item p {
    margin: 6px;
}

.job-list-item__contact {}

.job-list-item__seeking-description {
    margin: 40px;
}
.job-list-item__job-link {}

.job-list-item__delete_button {
    margin: 8px;
}