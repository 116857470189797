.home-page {}

.home-page__title {}

.home-page__intro {
    margin: 10px;
}

.home-page__buttons-box {}

.home-page__buttons-box .button-link {
    margin: 0px 5px;
}

.home-page__admin-link-box {
    margin: 25px auto;
    background-color: black;
    padding: 10px;
}