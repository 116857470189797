.table-inlaid {
    border-width: 0px;
    width: 100%;
}

.tr-inlaid td {
    border-width: 1px;
}

.tr-inlaid__first-row > td {
    border-top: 0;
}