.item-box {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: #333;
}

.item-box-hover:hover {
    background-color: #111;
}

/* Shimmer - for placeholders */
.item-box-shimmer {
    background: linear-gradient(to right, #333 15%, #383838 30%, #333 45%);
    animation: itemBoxShimmer 3s linear 0s infinite normal forwards;
}

@keyframes itemBoxShimmer {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}