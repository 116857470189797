.candidate-list-item {}

.candidate-list-item__name-tagline-box {
    margin: 8px;
}
.candidate-list-item__name {
    margin: 5px;
}
.candidate-list-item__tagline {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
}

.candidate-list-item p {
    margin: 6px;
}

.candidate-list-item__website {}
.candidate-list-item__seeking-description {}

.candidate-list-item__delete_button {
    margin: 8px;
}