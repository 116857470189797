.candidate-form {}

.candidate-form__textarea-input {
    height: 50px;
    margin: 0 auto;
}

.candidate-form__submit-button {
    margin: 20px 0px;
}

.candidate-form__tagline {
    width: 95%;
    max-width: 530px;
}