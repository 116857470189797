.candidate-page__name{
    margin: 6px;
    color: #c99;
}

.candidate-page__tagline {
    margin: 0px;
    font-weight: bold;
    font-size: 14px;
}

.candidate-page__back-links {
    margin: 40px 0px;
}

.candidate-page__back-links button {
    margin: 0px 8px;
}

.candidate-page__description-title {
    margin: 0px;
}

.candidate-page__delete-button {
    margin: 8px;
}

.candidate-page p {
    margin: 0px auto 15px auto;
}