.admin-home-page__info-panel {
    display: inline-block;
    background-color: black;
    border-radius: 8px;
    padding: 10px;
    border-style: solid;
    border-color: #555;
    border-width: 1px;
    margin: 10px 15px;
}

.admin-home-page__username-label {
    background-color: black;
    border-radius: 5px;
    padding: 5px;
}

.admin-home-page__listed-table {
    display: inline-block;
    margin: 8px;
}

.admin-home-page__listed-table h3 {
    margin: 12px;
}

.admin-home-page__panel-header {
    margin: 15px;
}


.admin-home-page__error-message {
    color: red;
}

.admin-home-page__json-textarea {
    width: 95%;
    max-width: 800px;
    height: 1200px;
}
