html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
}

p, h1,h2,h3,h4,h5,h6, label, button {
  color: white;
}

a {
  color: rgb(255, 45, 0);
}

footer {
  bottom: 25px;
  position: absolute;
  width: 100%;
}

button {
  padding: 8px;
  background-color: #444;
  font-size: 15px;
  cursor: pointer;
}

button:hover {
  background-color: #777;
}

.warn-button {
  background-color: darkred;
}

.warn-button:hover {
  background-color: red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea {
  padding: 2px;
  font-size: 12px;
}

table {
  color: white;
  border-collapse: separate;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  background-color: #333;
}

td {
  border-left: solid white 1px;
  border-top: solid white 1px;
  padding: 5px;
}

th {
  border-left: solid white 1px;
}

td:first-child, th:first-child {
  border-left: none;
}

tr td:first-child {
  font-weight: bold;
}

.button-link {
  text-decoration: none;

  appearance: none;
}

.phone-input {
  caret-color: transparent;
}

.wide-input {
  width: 90%;
  max-width: 450px;
}

.email-input {
  width: 90%;
  max-width: 210px;
}

.url-input {
  width: 90%;
  max-width: 320px;
}

.full-name-input {
  width: 80%;
  max-width: 200px;
}