.list-item {
    width: 95%;
    max-width: 650px;
    margin: 8px auto;
    text-align: center;
}

.list-item-placeholder-text {
    font-size: 10px;
    color: #555;
    background-color: #555;
    margin: 10px auto;
    border-radius: 3px;
    text-align: center;
}

.list-item-placeholder-lg {
    width: 90%;
    max-width: 350px;
}

.list-item-placeholder-md {
    width: 70%;
    max-width: 275px;
}

.list-item-placeholder-sm {
    width: 45%;
    max-width: 175px;
}