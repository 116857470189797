.job-page__job-title {
    margin: 0px;
    color: #c99;
}
.job-page__company-name {
    margin: 0px;
    color: #c99;
}

.job-page__subheader {
    margin: 15px 0px 8px 0px;
}

.job-page__delete-button {
    margin: 8px;
}

.job-page p {
    margin: 6px 0px;
}

.job-page__back-links {
    margin: 40px 0px;
}

.job-page__back-links button {
    margin: 0px 8px;
}